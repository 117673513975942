import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadComponent: () =>
			import('./notifications.component').then(
				c => c.ParticipationNotificationsSelectorComponent
			),
		children: [
			{
				path: 'create',
				loadComponent: () =>
					import('../../editors/notifications/notifications.component').then(
						c => c.ParticipationNotificationsEditorComponent
					)
			},
			{
				path: 'edit/:id',
				loadComponent: () =>
					import('../../editors/notifications/notifications.component').then(
						c => c.ParticipationNotificationsEditorComponent
					)
			}
		]
	},

	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ParticipationNotificationRoutingModule {}
